aside {
    width: 30%;
    padding-right: .5rem;
    margin-right: .5rem;
    float: left;
    box-shadow: inset -5px 0 5px -5px #29627e;
    text-align: right;
    color: #29627e;
}

aside > p {
    margin: .5rem;
}

p {
    font-family: 'Fira Sans', sans-serif;
}