* {
    margin: 0;
    padding: 0;
}

#wrapper {
    width: 85%;
    margin: 0 auto;
    padding: 25px;
}

#header {
    /*background: black;*/
    /*color: white;*/
    text-align: center;
}

#column-left {
    width: 25%;
    float: left;
    padding: 10px;
}

#column-center {
    width: 50%;
    float: left;
    padding: 10px;
}

#column-right {
    width: 20%;
    float: left;
}

#footer {
    /*background: green;*/
    clear: both;
    padding: 10px;
    text-align: center;
}

section {
    padding-bottom: 25px;
}

ul {
    padding-left: 50px;
    padding-right: 25px;
}

p {
    padding: 15px;
}

.centerStyle {
    text-align: center;
}

.boldCenterStyle {
    text-align: center;
    font-weight: bold;
}